/* fonts import  */

@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;500&family=League+Spartan:wght@400;500;700&family=Montserrat:wght@500&display=swap");
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "League Spartan";
  user-select: none !important;
  scroll-behavior: smooth;
}
img {
  pointer-events: none;
}
:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
  --color-primary: #194280;
}

.color-primary {
  color: var(--color-primary);
}
body {
  scrollbar-width: thin;
}
#login input {
  border: 1px solid black;
  transition: 0.3s;
}
#signup form button[type="submit"]:hover,
#login form button[type="submit"]:hover {
  transition: 0.5s;
  background-color: rgb(28, 74, 171) !important;
}
input,
select {
  border: 1px solid #a5b0cb;
  border-radius: 4px;
  font-size: 14px;
  transition: 0.5s;
}
select {
  min-width: 15ch;
  font-size: 14px;
}
input:focus,
select:focus {
  outline: none !important;
}

.slick-next:before {
  content: none;
}
.slick-prev:before {
  content: none;
}
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.slick-next {
  right: 1% !important;
}

/* accordion  */
.accordion {
  --bs-accordion-border-color: none !important;
  --bs-accordion-bg: none !important;
  --bs-accordion-border-radius: none !important;
}

.custom-accordion .accordion-button {
  background-color: none !important;
  padding: 0 !important;
  color: white !important;
  box-shadow: none !important;
  font-size: 20px !important;
  transition: all 0.3s ease-in-out !important;
}

.custom-accordion2 .accordion-button {
  background-color: none !important;
  padding: 0 !important;
  color: white !important;
  box-shadow: none !important;
  font-size: 20px !important;
  transition: all 0.3s ease-in-out !important;
  justify-content: center !important;
}

.accordion-body {
  padding: 0;
  background-color: none !important;
  color: black !important;
}

.custom-accordion .accordion-item:last-of-type .accordion-button.collapsed {
  justify-content: center;
  position: relative;
}
.custom-accordion2 .accordion-item:last-of-type .accordion-button.collapsed {
  justify-content: center;
  position: relative;
  padding: 6px 8px !important;
}

.custom-accordion .accordion-item:first-of-type .accordion-button {
  justify-content: center;
  position: relative !important;
}
.custom-accordion2 .accordion-item:first-of-type .accordion-button {
  justify-content: center;
  position: relative !important;
}

.custom-accordion
  .accordion-item:first-of-type
  .accordion-button.collapsed::after {
  content: "";
  position: absolute !important;
  width: 0% !important;
}
.custom-accordion2
  .accordion-item:first-of-type
  .accordion-button.collapsed::after {
  content: "";
  position: absolute !important;
  width: 0% !important;
}
.custom-accordion .accordion-item:first-of-type .accordion-button::after {
  content: "";
  position: absolute;
  background-color: white !important;
  width: 70% !important;
  height: 3px !important;
  bottom: 6px !important;
  left: 50% !important;
  transform: translateX(-50%);
}
.custom-accordion2 .accordion-item:first-of-type .accordion-button::after {
  content: "";
  position: absolute;
  width: 0 !important;
  height: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
}

.custom-accordion .accordion-button:not(.collapsed) {
  background-color: #20314f !important;
  padding: 10px 12px !important;
  border-radius: 12px 12px 0px 0px !important;
}
.custom-accordion2 .accordion-button:not(.collapsed) {
  background-color: #20314f !important;
  padding: 10px 12px !important;
  border-radius: 0px !important;
}
.custom-accordion-admin .accordion-button:not(.collapsed) {
  background-color: #20314f !important;
  padding: 10px 12px !important;
  border-radius: 0px !important;
  border-bottom: 3px solid white !important;
}
/* child accordion  */
.child-accordion .accordion-item:last-of-type .accordion-button.collapsed {
  justify-content: start;
  padding: 10px 12px !important;
  position: relative;
}
.child-accordion .accordion-item:first-of-type .accordion-button {
  justify-content: start;
  position: relative !important;
}

.child-accordion .accordion-item:first-of-type .accordion-button::after {
  content: "";
  width: 0% !important;
}
.child-accordion .accordion-button {
  background-color: #063e70 !important;
  padding: 0 !important;
  color: white !important;
  box-shadow: none !important;
  font-size: 16px !important;
  background-image: url("../images/accArrow1.svg") !important;
  background-repeat: no-repeat !important;
  background-position-x: 95% !important;
  background-position-y: center !important;
  transition: all 0.3s ease-in-out !important;
}

.child-accordion .accordion-button:not(.collapsed) {
  padding: 10px 12px !important;
  border-radius: 0px !important;
  background-color: #063e70 !important;
  background-image: url("../images/accArrow2.svg") !important;
  background-repeat: no-repeat !important;
  background-position-x: 95% !important;
  background-position-y: center !important;
}
.accordion-button::after {
  content: none;
}
.accordion-button:not(.collapsed) {
  display: flex;
  justify-content: center;
}

.custom-accordion-filter .accordion-button {
  box-shadow: none !important;
  background: #023685;
  color: white !important;
}

.custom-accordion-filter .accordion-button:not(.collapsed) {
  display: flex;
  justify-content: start;
}

.login-input {
  border-radius: 7px;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  background-color: rgba(137, 140, 221, 0.15);
}
.login-hover:hover {
  background-color: white !important;
  color: #194280 !important;
  transition: all 0.4s ease-in-out;
}
.hover-circle:hover {
  background-color: #dfe3e4;
  cursor: pointer;
}
.hover-square:hover {
  background-color: #194280;
}
.hover-square:hover .icons {
  color: #ffff !important;
}
.hover-circle:hover .icons {
  color: black !important;
}
a {
  text-decoration: none;
  color: black;
}
.admin-hvr:hover {
  background-color: #194280 !important;
}
.services-hvr:hover {
  background-color: #275986 !important;
}

/* add new worker  */
.bg-worker {
  background-color: white;
  border-radius: 10px;
}
.underline::after {
  content: "";
  position: absolute;
  background-color: rgba(25, 66, 128, 1);
  width: 100px;
  height: 3px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
}
input {
  border: none;
}
.borderBottom {
  border-bottom: 1.5px solid black !important;
  border-radius: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* dropdown  */
.dropdown-toggle::after {
  content: "";
  vertical-align: middle;
  border-width: 0.35em 0.35em 0;
  border-color: black transparent transparent transparent;
}
.dropdown-menu {
  width: 200px;
}
.dropdown-menu a:hover {
  color: #194280;
  padding-left: 30px;
  transition: all 0.3s ease-in-out;
}
.dropdown_hvr:hover {
  border-bottom: 1px solid #194280;
}

table {
  border-collapse: collapse;
  width: 100%;
}
tbody {
  border: 1px solid black;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5c5c5;
}
.no-wrap {
  text-wrap: nowrap;
}

/* Custom scrollbar styling for selected bus seats */
.seat-names-scroll {
  overflow-x: auto;
  white-space: nowrap;
  max-width: 200px;
}

.seat-names-scroll::-webkit-scrollbar {
  height: 8px;
}

.seat-names-scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.seat-names-scroll::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.seat-names-scroll {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* assign rights  */
input[type="checkbox"] {
  height: 18px;
  width: 18px;
  margin-right: 15px;
}

#contact_number:focus {
  overflow-y: hidden !important;
}

/* dashboard home  */
.vert-line::after {
  content: "";
  position: absolute;
  background-color: rgba(217, 217, 217, 1);
  height: 2px;
  top: 38%;
  left: 40%;
  margin-top: 10px;
  width: 20%;
  transform: rotate(90deg);
}
.zoom,
.pdf-hvr {
  transition: all 0.3s ease-in-out !important;
}
.pdf-hvr:hover .zoom {
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  opacity: 1 !important;
}

/* .pdf-hvr:hover {
  filter: brightness(150%) !important;
} */
.slick-slider {
  left: 30px !important;
}
.slick-dots li button:before {
  content: "•";
  opacity: 1 !important;
  font-size: 10px !important;
  color: #c6d7ff !important;
}
.slick-dots li.slick-active button:before {
  color: #194280 !important;
}
.active-tab {
  background-color: #d1d1d1 !important;
  color: #194280 !important;
  font-weight: 600 !important;
  border-radius: 5px !important;
  padding: 4px 10px;
}

.message {
  font-size: 14px;
  /* margin-top: 5px;  */
}

.green {
  color: green;
}

.red {
  color: red;
}

/* toggle switch  */
.switch {
  position: relative;
  display: inline-block;
  width: 145px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d9d9d9;
  border: 1.5px solid #148c43;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: attr(data-content);
  height: 28px;
  width: 68px;
  right: 0px;
  bottom: 0px;
  background-color: #148c43;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  padding: 2px 8px;
  color: white;
}

input:checked + .slider {
  background-color: #d9d9d9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #d9d9d9;
}

input:checked + .slider:before {
  content: attr(data-content, "Inactive");
  -webkit-transform: translateX(-74.5px);
  -ms-transform: translateX(-74.5px);
  transform: translateX(-74.5px);
  background-color: #dd4026;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  content: attr(data-content, "Active");
  border-radius: 34px;
  text-align: center;
}
.help-modal .modal-content {
  height: 100%;
}
.help-modal .modal-dialog {
  height: calc(100% - 90px) !important;
}
.modal-dialog {
  max-width: 800px;
}
.form-check-input[type="radio"] {
  background-color: #d9d9d9;
}
.form-check-input:checked {
  background-color: #a6eb00;
  border: 0;
}
.helpdesk-icon {
  position: sticky;
  bottom: 0px;
  right: 20px;
  cursor: pointer;
  animation: floatAnimation 3s infinite ease-in-out;
  /* z-index: 9999; */
}
.modal.modal-right .modal-dialog {
  margin-left: auto;
  margin-right: 0;
  position: absolute;
  transform: translateX(100%);
  transition: transform 0.3s ease-out, width 0.3s ease-out;
  width: 37%;
  max-width: calc(100% - 15px);
  bottom: -3%;
  right: 8px;
  border-radius: 10px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.modal.modal-right.show .modal-dialog {
  transform: translateX(0);
}

.custom-upload-btn {
  display: inline-block;
  padding: 8px 16px;
  background-color: white;
  border: 1px solid rgba(105, 102, 102, 1);
  color: rgba(105, 102, 102, 1);
  border-radius: 10px;
  cursor: pointer;
  background-image: url("../images/upload.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}
.custom-upload-btn2 {
  background-color: white;
  border: 1px solid rgba(105, 102, 102, 1);
  color: rgba(105, 102, 102, 1);
  border-radius: 10px;
  cursor: pointer;
  background-image: url("../images/upload.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) center;
  width: 200px;
  padding: 4px 10px;
}

.file-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}
.file-container2 {
  display: flex;
  margin-top: 8px;
}

.file-box {
  width: 50px;
  height: 35px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: 1px solid black;
  padding: 3px 15px;
  background-image: url("../images/cross.svg");
  background-repeat: no-repeat;
  background-position-x: 105%;
  background-position-y: -6%;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.file-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #ccc;
}

.full-screen.modal.modal-right .modal-dialog {
  width: 76.7% !important;
  transform: none;
}

.full-screen.modal.modal-right .modal-body {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.reset_desc {
  transition: all 0.3s ease-in-out;
}
.reset_hvr:hover .reset_desc {
  opacity: 1 !important;
}
.custom-modal .modal-content {
  background: none !important;
  border: 0 !important;
}
.modal-backdrop-transparent {
  opacity: 0 !important;
}

.swal-title {
  font-size: 23px;
}

.swal-confirm-button {
  width: 130px !important;
  background-color: #194280 !important;
}

.swal-cancel-button {
  width: 100px;
  color: black !important;
  background-color: white !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #194280;
  width: 130px !important;
  color: #fff;
}

/* date picker  */
.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 35px !important;
}

.react-datepicker__close-icon::after {
  content: "x" !important;
  color: #194280;
  background-color: transparent;
  font-size: 22px;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: transparent !important;
  outline: 0;
  box-shadow: none !important;
}
.form-control {
  background-color: #fff6d8 !important;
  border: none !important;
  width: 300px !important;
}

.dash_line::after {
  content: "";
  position: absolute;
  border: 1.4px dashed #696666;
  width: 47px;
  transform: rotate(-90deg);
  bottom: -27px;
}
.dash_line2::after {
  content: "";
  position: absolute;
  border: 1.4px dashed #696666;
  width: 80px;
  transform: rotate(-90deg);
  left: 35px;
  bottom: -42px;
}

select.hide_arrow {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background-image: none !important;
  background-repeat: no-repeat !important;
}
.active {
  background-color: #007bff !important;
  color: #fff;
  font-weight: bold;
}
.active-service {
  background-color: #275986 !important;
}
.ql-toolbar.ql-snow {
  border: none !important;
}
.ql-container.ql-snow {
  border: none !important;
  height: 257px !important;
}
.ql-editor table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
}

.ql-editor th,
.ql-editor td {
  padding: 8px;
  border: 1px solid #ddd;
}
.workflow_selected_tab::after {
  content: "";
  position: absolute;
  height: 1.2px;
  width: 100%;
  left: 100%;
  bottom: -5px;
  display: block !important;
  background-color: #696666;
}
.ql-toolbar .ql-formats-right {
  float: right;
}

.ql-toolbar .ql-formats-left {
  float: left;
}

.ql-toolbar .ql-formats-center {
  float: none;
  display: inline-block;
}

.ql-tooltip {
  right: 0 !important;
  left: auto !important;
}
.ql-video {
  max-width: 100%;
  height: auto;
}
.ql-reset {
  background-image: url("../images/reset.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 18px !important;
  width: 18px !important;
}

/* progress tracker  */
.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
}

.filled {
  background-color: #ad8352;
}

.hollow {
  border: 2px solid #ad8352;
  background-color: transparent;
}
.reverted {
  border: 0px solid transparent;
  background-clip: padding-box;
  box-shadow: inset 0 0 5px 2px #ad8352;
}
.line {
  width: 100px;
  height: 1.5px;
  background-color: #ad8352;
  margin-top: -78px;
}

/* services  *******/
.selected_service::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #000;
  bottom: 0;
  left: 0;
}
.table-container {
  max-height: calc(100vh - 300px) !important;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
  padding: 8px 15px;
  text-align: left;
}

.transaction-table thead {
  background-color: rgba(217, 217, 217, 1);
}

.custom-date-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.rotating {
  animation: rotate 1s linear;
}
.spin {
  animation: spin 2s linear infinite;
}

.dmt_select .css-1fdsijx-ValueContainer {
  padding: 0px !important;
}

.dmt_select .css-1jwid8t-control {
  border-bottom: 1px solid #adacac !important;
}
.bene_hvr {
  transition: all 0.3s ease-in-out;
}

.bene_hvr:hover {
  background-color: #d9d9d9 !important;
  cursor: pointer;
}
.rotate-180 {
  transform: rotate(-180deg) !important;
}
.swap-hover {
  transition: all 0.3s ease-in-out;
}
.swap-hover:hover {
  background-color: #d9d9d9 !important;
}
.custom-toast .Toastify__close-button {
  color: white !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (min-width: 1400px) {
  .business_brand_height {
    height: 800px !important;
  }
}
@media (min-width: 1900px) {
  .img_viewer_container {
    height: 220px !important;
  }
  .home_sec1 {
    height: 20% !important;
  }
  .home_sec2 {
    height: 74% !important;
  }
  .slider_img {
    width: 70% !important;
  }
  .login_text {
    font-size: 20px !important;
  }
  .trans_text {
    font-size: 18px !important;
  }
  .home_text {
    font-size: 28px !important;
  }
  .table_font {
    font-size: 15px;
  }
  .full-screen.modal.modal-right .modal-dialog {
    width: 84% !important;
    transform: none;
  }
  .fs_xl {
    font-size: 15px !important;
  }
}
